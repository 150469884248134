import { combineReducers, configureStore } from '@reduxjs/toolkit'
import campaignTableFiltersReducer from './pages/Campaign/CampaignTable/CampaignTableFilters/slice'
import detailsFormSlice from './pages/Campaign/CampaignForm/DetailsForm/store/details-form-slice'
import mapSlice from './components/Map/store/map-slice'
import creationAcceptancesSlice from './pages/Campaign/CampaignForm/CreationsForm/store/creation-acceptances-slice'
import userTableSlice from './pages/User/UserTable/store/user-table-slice'
import reportSlice from './pages/Report/store/report-slice'
import roleFormSlice from './pages/Roles/RoleForm/store/role-form-slice'
import rolesTableSlice from './pages/Roles/RolesTable/store/roles-table-slice'
import campaignFormSlice from './pages/Campaign/store/campaign-form-slice'
import emissionPlanSlice from './pages/EmissionPlan/store/emission-plan-slice'
import broadSignSlice from './pages/BroadSign/store/broad-sign-slice'
import galleryOccupancySlice from './pages/Inventory/store/inventory-gallery-occupancy-slice'
import topTenGalleriesSlice from './pages/Inventory/store/inventory-top-ten-galleries-slice'
import campaignDetailsSlice from './pages/Campaign/CampaignDetails/store/campaign-details-slice'
import inventoryCommonSlice from './pages/Inventory/store/inventory-common-slice'
import campaignEmissionScheduleSlice from './pages/Campaign/EmissionSchedule/store/campaign-emission-schedule-slice'

export const store = configureStore({
  reducer: {
    campaignTableFilters: campaignTableFiltersReducer,
    detailsForm: detailsFormSlice.reducer,
    creationAcceptances: creationAcceptancesSlice.reducer,
    map: mapSlice.reducer,
    userTable: userTableSlice.reducer,
    rolesTable: rolesTableSlice.reducer,
    roleForm: roleFormSlice.reducer,
    report: reportSlice.reducer,
    campaignForm: campaignFormSlice.reducer,
    campaignDetails: campaignDetailsSlice.reducer,
    campaignEmissionSchedule: campaignEmissionScheduleSlice.reducer,
    emissionPlan: emissionPlanSlice.reducer,
    broadSign: broadSignSlice.reducer,
    inventory: combineReducers({
      common: inventoryCommonSlice.reducer,
      galleryOccupancy: galleryOccupancySlice.reducer,
      topTenGalleries: topTenGalleriesSlice.reducer,
    }),
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
