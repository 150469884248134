import { gql } from '@apollo/client'

export const BROADSIGN_CREATIONS = gql`
  query BroadsignCreations($filters: BroadsignCreationFilters, $first: Int, $last: Int) {
    broadsignCreations(filters: $filters, first: $first, last: $last) {
      nodes {
        id
        broadsignId
        mediaFormat
        createdAt
        frameFormat
        duration
        status
        width
        height
        details
        file {
          filename
          url
          isVideo
          mimeType
        }
        thumbnailUrl
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
