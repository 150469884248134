import React, { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import './NavbarItem.scss'

interface NavbarItemProps {
  to: string
  icon: IconDefinition
  children?: string | React.ReactNode
  title?: string
  dataCy?: string
}

const NavBarItem = (icon: IconDefinition, children: string): ReactNode => (
  <div className='NavbarItem'>
    <div className='NavbarItem__icon--container'>
      <FontAwesomeIcon
        icon={icon}
        className='NavbarItem__icon'
      />
    </div>
    <div className='NavbarItem__text'>{children}</div>
  </div>
)

const NavItem: React.FC<NavbarItemProps> = ({ to, icon, children, title, dataCy }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      'NavbarItem__container' + (isActive ? ' NavbarItem__container--active' : '')
    }
    title={title}
    data-cy={dataCy}
  >
    <div className='NavbarItem__marker' />

    {typeof children === 'string' || children instanceof String ? (
      NavBarItem(icon, children as string)
    ) : (
      <div>{children}</div>
    )}
  </NavLink>
)

export default NavItem
