import { CampaignEmission } from './types'
import Colors from '../../../../styles/colors.module.scss'
import { CampaignTarget } from '../../../../types/campaign'

export default class ChartSupportService {
  static getCellColor = (
    target: CampaignTarget | undefined,
    campaignEmission: CampaignEmission,
    index: number,
    focusBar: number | null
  ): string => {
    if (target === CampaignTarget.IMPRESSIONS && focusBar === index) {
      return Colors.blue
    }

    if (target === CampaignTarget.IMPRESSIONS) {
      return Colors.chartBar
    }

    if (campaignEmission.estimated && focusBar === index) {
      return Colors.gray5
    }

    if (campaignEmission.estimated) {
      return Colors.gray4
    }

    if (focusBar === index) {
      return Colors.blue
    }

    return Colors.chartBar
  }

  static getTooltipTopTranslationKey = (
    audienceMode: boolean,
    estimated: boolean | null
  ): string => {
    if (audienceMode && estimated) {
      return 'common.numberOfAudienceEstimated'
    }

    if (audienceMode) {
      return 'common.numberOfAudience'
    }

    return 'common.numberOfEmissions'
  }

  static getTooltipBottomTranslationKey = (
    audienceMode: boolean,
    estimated: boolean | null
  ): string => {
    if (audienceMode && estimated) {
      return 'campaignDetails.chart.sumOfAudienceEstimated'
    }

    if (audienceMode) {
      return 'campaignDetails.chart.sumOfAudience'
    }

    return 'campaignDetails.chart.sumOfEmissions'
  }

  static getYaxisDataKey(
    emissions: CampaignEmission[],
    target?: CampaignTarget,
    dealId?: number | null
  ): string {
    /**
     * 1) This is an ordinary campaign (no broadsign data)
     */
    if (!dealId) {
      return target === CampaignTarget.IMPRESSIONS ? 'emissions' : 'audience'
    }

    /**
     * 2) This is a broadsign campaign and broadsign data can contain higher values than emissions or audience
     */
    const maxEmission = Math.max(...emissions.map(emission => emission.emissions))
    const maxAudience = Math.max(...emissions.map(emission => emission.audience))
    const maxNoAdCount = Math.max(...emissions.map(emission => emission.noAdCount ?? 0))

    if (target === CampaignTarget.IMPRESSIONS) {
      return maxEmission >= maxNoAdCount ? 'emissions' : 'noAdCount'
    }

    if (target === CampaignTarget.AUDIENCE) {
      return maxAudience >= maxNoAdCount ? 'audience' : 'noAdCount'
    }

    return 'emissions'
  }
}
