import React from 'react'
import Table, {
  TableBody,
  TableData,
  TableHeader,
  TableHeaderItem,
  TableRow,
} from 'components/Table'
import { useTranslation } from 'react-i18next'
import './BroadSignCreationsTable.scss'
import Pagination from '../../../../components/Pagination'
import { DATE_FORMAT, ITEMS_PER_PAGE } from '../../../../constant'
import { BroadSignCreationModel } from '../../../../api/broadSign/models/broad-sign-creation.model'
import DateUtils from '../../../../utils/date'

const BroadSignCreationsTable: React.FC<{
  pageNumber: number
  totalItemsCount: number
  data: BroadSignCreationModel[]
  rowClick: (creation: BroadSignCreationModel) => void
  setPageNumber: (page: number) => void
}> = ({ pageNumber, totalItemsCount, data, rowClick, setPageNumber }) => {
  const { t } = useTranslation()

  if (!data.length) {
    return (
      <div className='BroadSignCreationsTable__no-data'>
        <p>{t('emissionPlan.noData')}</p>
      </div>
    )
  }

  return (
    <div className='BroadSignCreationsTable__table'>
      <Table className='CommonTable'>
        <TableHeader sticky>
          <>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.icon')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.name')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.id')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.mediaFormat')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.duration')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.resolution')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.createdAt')}
            </TableHeaderItem>
            <TableHeaderItem className='CommonTable__header-item'>
              {t<string>('broadSign.status')}
            </TableHeaderItem>
          </>
        </TableHeader>

        <TableBody>
          {data.map((creation, i: number) => (
            <TableRow
              key={i}
              className='CommonTable__data-row'
              onClick={() => rowClick(creation)}
            >
              <>
                <TableData
                  key={`${i}-icon`}
                  className='CommonTable__data-item BroadSignCreationsTable__icon-row'
                >
                  <img
                    className='BroadSignCreationsTable__preview'
                    src={creation.thumbnailUrl}
                    alt={creation.file.filename}
                  />
                </TableData>
                <TableData
                  key={`${i}-id`}
                  className='CommonTable__data-item'
                >
                  <span>{creation.details ?? '-'}</span>
                </TableData>
                <TableData
                  key={`${i}-id`}
                  className='CommonTable__data-item'
                >
                  <span>{creation.broadsignId}</span>
                </TableData>
                <TableData
                  key={`${i}-id`}
                  className='CommonTable__data-item'
                >
                  <span>{creation.mediaFormat ?? '-'}</span>
                </TableData>
                <TableData
                  key={`${i}-duration`}
                  className='CommonTable__data-item'
                >
                  <span>{creation.duration + 's'}</span>
                </TableData>
                <TableData
                  key={`${i}-resolution`}
                  className='CommonTable__data-item'
                >
                  <span>{creation.width + 'x' + creation.height + 'px'}</span>
                </TableData>
                <TableData
                  key={`${i}-createdAt`}
                  className='CommonTable__data-item'
                >
                  <span>{DateUtils.parseAndFormat(creation.createdAt, DATE_FORMAT)}</span>
                </TableData>
                <TableData
                  key={`${i}-status`}
                  className='CommonTable__data-item'
                >
                  <span>{t<string>(`broadSign.${creation.status}`)}</span>
                </TableData>
              </>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        activePage={pageNumber}
        itemsCountPerPage={ITEMS_PER_PAGE}
        totalItemsCount={totalItemsCount}
        onChange={setPageNumber}
      />
    </div>
  )
}

export default BroadSignCreationsTable
