import { CampaignDetailsSliceModel } from '../../models/campaign-details-slice.model'
import { Medium } from '../../../../types/campaign'

export default class ChartFiltersSupportService {
  /**
   * This function returns all available filters (default state) - no filter is selected
   *
   * @param displayedMedia
   * @param campaignData
   * @param campaignDetails
   */
  static getAllFiltersByDisplayedMediaAndCampaign = (
    displayedMedia: Medium[],
    campaignData: CampaignDetailsSliceModel['campaignData'],
    campaignDetails: CampaignDetailsSliceModel['campaignDetails']
  ): CampaignDetailsSliceModel['filters']['available'] => {
    return {
      agglomerations: displayedMedia
        .map(media => media.agglomeration?.name || '')
        .filter((el, index, array) => !!el && array.indexOf(el) === index),
      buildings: displayedMedia
        .map(media => media.building?.name || '')
        .filter((el, index, array) => !!el && array.indexOf(el) === index),
      cities: displayedMedia
        .map(media => media.city.name)
        .filter((el, index, array) => array.indexOf(el) === index),
      mediaAsIds: displayedMedia.map(media => media.asId.toString()),
      pois: displayedMedia
        .map(media => media.poiCategories)
        .filter((el, index, array) => !!el && array.indexOf(el) === index)
        .flat(),
      creations: campaignData ? campaignData.creations.map(creation => creation.id) : [],
      broadsignCreationIds:
        campaignDetails && campaignDetails.broadsignCreationIds
          ? campaignDetails.broadsignCreationIds.map((id: number) => id.toString())
          : [],
    }
  }

  static filterByAgglomerations = (
    selected: string[],
    availableFilters: CampaignDetailsSliceModel['filters']['available'],
    displayedMedia: Medium[]
  ): CampaignDetailsSliceModel['filters']['available'] => {
    const matchingAgglomeration = (displayedMedia: Medium) => {
      return selected.some(agglomeration => displayedMedia.agglomeration?.name === agglomeration)
    }

    const reducedAvailableFilters: CampaignDetailsSliceModel['filters']['available'] = {
      ...availableFilters,
      cities: selected.length
        ? displayedMedia
            .filter(matchingAgglomeration)
            .map(medium => medium.city.name)
            .filter(ChartFiltersSupportService.removeDuplicates)
        : displayedMedia
            .map(medium => medium.city.name)
            .filter(ChartFiltersSupportService.removeDuplicates),
      buildings: selected.length
        ? displayedMedia
            .filter(matchingAgglomeration)
            .map(medium => medium.building?.name)
            .filter(ChartFiltersSupportService.removeDuplicates)
            .filter((name): name is string => !!name)
        : displayedMedia
            .map(media => media.building?.name)
            .filter(ChartFiltersSupportService.removeDuplicates)
            .filter((name): name is string => !!name),
      mediaAsIds: selected.length
        ? displayedMedia
            .filter(matchingAgglomeration)
            .map(medium => medium.asId)
            .filter(ChartFiltersSupportService.removeDuplicates)
        : displayedMedia
            .map(medium => medium.asId)
            .filter(ChartFiltersSupportService.removeDuplicates),
    }

    return { ...reducedAvailableFilters }
  }

  static filterByCities = (
    selected: string[],
    availableFilters: CampaignDetailsSliceModel['filters']['available'],
    displayedMedia: Medium[]
  ): CampaignDetailsSliceModel['filters']['available'] => {
    const matchingCity = (displayedMedia: Medium) => {
      return selected.some(city => displayedMedia.city?.name === city)
    }

    const reducedAvailableFilters: CampaignDetailsSliceModel['filters']['available'] = {
      ...availableFilters,
      buildings: selected.length
        ? displayedMedia
            .filter(matchingCity)
            .map(medium => medium.building?.name)
            .filter(ChartFiltersSupportService.removeDuplicates)
            .filter((name): name is string => !!name)
        : displayedMedia
            .map(media => media.building?.name)
            .filter(ChartFiltersSupportService.removeDuplicates)
            .filter((name): name is string => !!name),
      mediaAsIds: selected.length
        ? displayedMedia
            .filter(matchingCity)
            .map(medium => medium.asId)
            .filter(ChartFiltersSupportService.removeDuplicates)
        : displayedMedia
            .map(medium => medium.asId)
            .filter(ChartFiltersSupportService.removeDuplicates),
    }

    return { ...reducedAvailableFilters }
  }

  static filterByBuildings = (
    selected: string[],
    availableFilters: CampaignDetailsSliceModel['filters']['available'],
    displayedMedia: Medium[]
  ): CampaignDetailsSliceModel['filters']['available'] => {
    const matchingBuilding = (displayedMedia: Medium) => {
      return selected.some(building => displayedMedia.building?.name === building)
    }

    const reducedAvailableFilters: CampaignDetailsSliceModel['filters']['available'] = {
      ...availableFilters,
      mediaAsIds: selected.length
        ? displayedMedia
            .filter(matchingBuilding)
            .map(medium => medium.asId)
            .filter(ChartFiltersSupportService.removeDuplicates)
        : displayedMedia
            .map(medium => medium.asId)
            .filter(ChartFiltersSupportService.removeDuplicates),
    }

    return { ...reducedAvailableFilters }
  }

  static filterByMediaAsIds = (
    selected: string[],
    availableFilters: CampaignDetailsSliceModel['filters']['available'],
    displayedMedia: Medium[]
  ): CampaignDetailsSliceModel['filters']['available'] => {
    const reducedAvailableFilters: CampaignDetailsSliceModel['filters']['available'] = {
      ...availableFilters,
      mediaAsIds: displayedMedia
        .map(medium => medium.asId)
        .filter(ChartFiltersSupportService.removeDuplicates),
    }

    return { ...reducedAvailableFilters }
  }

  // TODO: just moved old function, refactor at some point
  static removeDuplicates = (
    element: string | number | undefined,
    index: number,
    array: (string | number | undefined)[]
  ): '' | 0 | undefined | boolean => {
    return element && array.indexOf(element.toString()) === index
  }
}
