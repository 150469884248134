import React from 'react'
import { useTranslation } from 'react-i18next'
import './BroadSignVideoPreviewModal.scss'
import Modal from '../../../../components/Modal'
import Button, { ButtonTheme } from '../../../../components/Form/Button'
import { BroadSignCreationModel } from '../../../../api/broadSign/models/broad-sign-creation.model'

const BroadSignVideoPreviewModal: React.FC<{
  creation?: BroadSignCreationModel
  isModalOpen: boolean
  setModalOpen: (open: boolean) => void
}> = ({ creation, isModalOpen, setModalOpen }) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={`${t('broadSign.preview')}`}
      classNameOverlay='BroadSignVideoPreviewModal'
      isOpen={isModalOpen}
      onClose={(isOpen: boolean) => void setModalOpen(isOpen)}
      footer={
        <div className={'BroadSignVideoPreviewModal__footer'}>
          <Button
            theme={ButtonTheme.BLUE}
            onClick={() => void setModalOpen(false)}
          >
            {t('common.close')}
          </Button>
        </div>
      }
    >
      {isModalOpen && (
        <div>
          <video
            className={'BroadSignVideoPreviewModal__video'}
            width='100%'
            height='100%'
            controls
          >
            <source
              src={creation?.file.url}
              type={creation?.file.mimeType}
            />
            {t('broadSign.noSupport')}
          </video>
        </div>
      )}
    </Modal>
  )
}

export default BroadSignVideoPreviewModal
