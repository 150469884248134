import { Campaign, CampaignChartFilters } from 'types/campaign'
import api from 'api'
import {
  AudienceFilters,
  CampaignEmission,
  CampaignImpressionResponse,
  CampaignLocalFilters,
} from './types'
import { PublicViewProps } from '../types'
import { store } from '../../../../store'
import { campaignDetailsActions } from '../store/campaign-details-slice'

export default class CampaignDetailsChartService {
  static fetchImpressions = (
    campaignId: Campaign['id'],
    publicViewProps: PublicViewProps | undefined,
    filters: CampaignLocalFilters,
    audienceFilters: AudienceFilters
  ): Promise<unknown> => {
    const broadsignCreationIds: number[] = filters.broadsignCreationIds
      ? filters.broadsignCreationIds.map(id => parseInt(id))
      : []
    const { pois } = filters
    const areAllPoisSelected = filters.pois.length === filters.pois.length
    const variables = {
      ...filters,
      broadsignCreationIds: broadsignCreationIds,
      pois: areAllPoisSelected ? [] : pois,
      sex: audienceFilters.sex,
      ageGroups: audienceFilters.ageGroups,
    }

    return Promise.resolve(store.dispatch(campaignDetailsActions.setChartLoading(true)))
      .then(() => {
        return CampaignDetailsChartService.fetchPureCampaignImpressions(
          campaignId,
          publicViewProps,
          variables
        )
      })
      .then(campaignResponseImpressions => {
        const emissions = campaignResponseImpressions.map(
          (impression: CampaignImpressionResponse): CampaignEmission => {
            return {
              audience: impression.audience,
              date: impression.date,
              totalAudience: impression.totalAudience,
              emissions: impression.impressions,
              totalEmissions: impression.totalImpressions,
              estimated: impression.estimated,
              broadsignImpressions: impression.broadsignImpressions,
              requestsCount: impression.requestsCount,
              adCount: impression.adCount,
              noAdCount: impression.noAdCount,
            }
          }
        )

        void store.dispatch(campaignDetailsActions.setChartEmissions(emissions))
        void store.dispatch(campaignDetailsActions.setChartLoading(false))
      })
  }

  static fetchPureCampaignImpressions = (
    campaignId: Campaign['id'],
    publicViewProps: PublicViewProps | undefined,
    variables: CampaignChartFilters
  ): Promise<CampaignImpressionResponse[]> => {
    const { publicView, campaignUuid } = publicViewProps || {}

    if (publicView && campaignUuid) {
      const query = api.campaign.getCampaignImpressionsPublic({
        campaignUuid,
        ...variables,
      })

      return query.then(res => {
        if (!res.data) {
          return []
        }

        return res.data.campaignReportPublic.campaignImpressions.nodes
      })
    }

    const query = api.campaign.getCampaignImpressions({
      campaignId,
      ...variables,
    })

    return query.then(res => {
      if (!res.data) {
        return []
      }

      return res.data.campaignImpressions.nodes
    })
  }
}
