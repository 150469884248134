import { PublicViewProps } from '../types'
import { Filters } from 'components/Form/Filters'

export interface CampaignDetailsChartProps {
  publicViewProps?: PublicViewProps
}

export interface RechartsComponentData {
  x?: number
  y?: number
  cx?: number
  cy?: number
  payload?: any
}

export interface CampaignEmission {
  audience: number
  date: string
  estimated: boolean | null
  emissions: number
  totalAudience: number
  totalEmissions: number
  broadsignImpressions: number | null
  requestsCount: number | null
  adCount: number | null
  noAdCount: number | null
}

export interface CampaignImpressionResponse {
  audience: number
  date: string
  estimated: boolean | null
  impressions: number
  totalAudience: number
  totalImpressions: number
  broadsignImpressions: number | null
  requestsCount: number | null
  adCount: number | null
  noAdCount: number | null
}

export const filterKeys = [
  'agglomerations',
  'cities',
  'buildings',
  'mediaAsIds',
  'pois',
  'creations',
  'broadsignCreationIds',
] as const

export type FilterKey = (typeof filterKeys)[number]

export type CampaignLocalFilters = Filters<FilterKey>

export const SexDefaults = [
  0, // MAN
  1, // WOMAN
]

export const AgeGroupDefaults = [
  0, // 0-15
  1, // 15-30
  2, // 30-45
  3, // 45-60
  4, // 60+
]

export interface AudienceFilters {
  sex: number[]
  ageGroups: number[]
}
